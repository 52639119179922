<template>
  <div class="md:pl-24 w-full h-screen max-h-screen flex flex-row font-visby text-new-black" :class="{'pt-safe' : parseInt(sat) !== 0}">
    <!-- Lernpfade -->
    <div class="w-full md:w-1/2 h-full pt-14 px-5 max-h-full overflow-hidden flex flex-col select-none md:max-w-4xl" :class="{'md:px-10' : !selectedLernpfadId}">
      <div v-if="!selectedLernpfadId" class="flex-shrink-0 mt-6 font-visby font-bold text-3xl">Lernpfade</div>
      <div v-if="!selectedLernpfadId" class="flex-shrink-0 mt-2 pb-5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar max-w-full">
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Alle'}" @click="currentFilter = 'Alle'">Alle</div>
        <div v-for="filter in filter" :key=filter class="cursor-pointer mr-3 select-none" @click="currentFilter = filter">
          <div :class="{'text-new-black' : filter === currentFilter}">{{filter}}</div>
        </div>
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Offen'}" @click="currentFilter = 'Offen'">Offen</div>
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Abgeschlossen'}" @click="currentFilter = 'Abgeschlossen'">Abgeschlossen</div>
      </div>
      <div v-if="!selectedLernpfadId" class="flex-grow overflow-auto scrollbar">
        {{  selectedLernpfadId }}
        <div v-for="lernpfad in filteredLernpfade" :key=lernpfad @click="setSelectedLernpfadId(lernpfad.Lernpfad.data.id)">
          <lernpfadPreview class="mb-5"
          :previewImage=lernpfad?.Lernpfad?.data?.attributes?.Vorschaubild?.data?.attributes?.url
          :title=lernpfad?.Lernpfad?.data?.attributes?.Bezeichnung
          :description=lernpfad?.Lernpfad?.data?.attributes?.Kurzbeschreibung
          :lernspotsDone=getFinishedLernspots(lernpfad.Lernpfad.data.id)
          :lernspotsTotal=lernpfad?.Lernpfad?.data?.attributes?.lernspots.length
          :horizontal="true"
          :cover="true"
          itemName="Lernspot" />
        </div>
        <div v-if="filteredLernpfade.length === 0" class="w-full flex flex-col justify-center items-center mt-10 p-5 text-center">
          <div>Keine Lernpfade verfügbar</div>
          <div class="text-new-midGrey">
            {{ currentFilter === 'Alle' ? 'In deiner Klasse scheinen in diesem Semester keine Lernpfade verfügbar zu sein. Wende dich an einen Administrator, falls das nicht stimmen sollte.' : `Sieht aus, als wären keine Lernpfade unter dem Filter "${currentFilter}" verfügbar.`}}
          </div>
        </div>
        <div v-if="mobile" class="mb-36"></div>
      </div>
      <div v-else class="flex-grow overflow-auto scrollbar pt-16">
        <div class="flex flex-row justify-start items-center px-3 cursor-pointer" @click="showAll()">
          <backSVG color='black' class="flex-shrink-0  md:h-10 md:w-10 md:p-2" />
          <div class="underline">Alle Lernpfade anzeigen</div>
        </div>
        <selectedLernpfadPreview />
      </div>
    </div>

    <!-- Selected Lernpfad -->
    <div class="hidden md:flex w-1/2 h-full bg-new-yellow3 px-5 max-h-full overflow-hidden flex-col md:flex-grow">
      <div class="flex-grow overflow-auto scrollbar pt-16">
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import mobile from '../../mixins/mobile.vue';
import learningFunctions from '../../mixins/learningFunctions.vue';

// Components
import lernpfadPreview from './components/lernpfadPreview.vue';
import selectedLernpfadPreview from './components/selectedLernpfadPreview.vue';

// SVG
import backSVG from '../../assets/svg/back.vue';

export default {
  name: 'learning',
  mixins: [iosVibrations, detectNotch, mobile, learningFunctions],
  components: { lernpfadPreview, selectedLernpfadPreview, backSVG },

  data() {
    return {
      currentFilter: 'Alle',
    };
  },

  computed: {
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    currentSemester() {
      return this.$store.state.settings.currentSemester;
    },
    lernpfade() {
      const lernpfade = this.$store.getters.availableLernpfade(this.currentCourseId);
      const lernpfadeBySemester = [];
      lernpfade.forEach((lernpfad) => {
        if (lernpfad.Semester <= this.currentSemester) {
          lernpfadeBySemester.push(lernpfad);
        }
      });
      return lernpfadeBySemester;
    },
    myIndividualLernpfade() {
      const myLernpfade = this?.me?.lernpfade?.data;
      const myFilteredLernpfade = [];
      myLernpfade.forEach((lernpfad) => {
        const lernpfadIndex = this.lernpfade.findIndex((Lernpfad) => Lernpfad.Lernpfad.data.id === lernpfad.id);
        if (lernpfadIndex === -1 && lernpfad?.attributes?.kurse?.data?.id === this.currentCourseId) myFilteredLernpfade.push(lernpfad);
      });
      return myFilteredLernpfade;
    },
    totalLernpfade() {
      const totalLernpfade = [];
      this.myIndividualLernpfade.forEach((lernpfad) => {
        totalLernpfade.push({ Lernpfad: { data: lernpfad } });
      });
      this.lernpfade.forEach((lernpfad) => {
        totalLernpfade.push(lernpfad);
      });
      return totalLernpfade;
    },
    lernpfadProgress() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress;
    },
    filter() {
      const filter = [];
      this.totalLernpfade.forEach((lernpfad) => {
        if (lernpfad.Lernpfad?.data?.attributes?.lernpfad_filter?.data?.length > 0) {
          lernpfad.Lernpfad.data.attributes.lernpfad_filter.data.forEach((lernpfadFilter) => {
            if (lernpfadFilter) {
              if (lernpfadFilter?.attributes?.Bezeichnung && !filter.includes(lernpfadFilter.attributes.Bezeichnung)) {
                filter.push(lernpfadFilter.attributes.Bezeichnung);
              }
            }
          });
        }
      });
      return filter;
    },
    filteredLernpfade() {
      const filteredLernpfade = [];
      if (this.currentFilter !== 'Alle' && this.currentFilter !== 'Offen' && this.currentFilter !== 'Abgeschlossen') {
        this.totalLernpfade.forEach((lernpfad) => {
          lernpfad.Lernpfad.data.attributes.lernpfad_filter.data.forEach((lernpfadFilter) => {
            if (lernpfadFilter?.attributes?.Bezeichnung && this.currentFilter === lernpfadFilter.attributes.Bezeichnung) {
              filteredLernpfade.push(lernpfad);
            }
          });
        });
        return filteredLernpfade;
      }
      if (this.currentFilter !== 'Alle') {
        const finishedLernpfade = [];
        const openLernpfade = [];
        this.totalLernpfade.forEach((lernpfad) => {
          const lernspotsDone = this.getFinishedLernspots(lernpfad.Lernpfad.data.id);
          const lernspotsTotal = lernpfad?.Lernpfad?.data?.attributes?.lernspots.length;
          if (lernspotsDone === lernspotsTotal) {
            finishedLernpfade.push(lernpfad);
          } else {
            openLernpfade.push(lernpfad);
          }
        });
        if (this.currentFilter === 'Offen') {
          return openLernpfade;
        }
        if (this.currentFilter === 'Abgeschlossen') {
          return finishedLernpfade;
        }
      }
      return this.totalLernpfade;
    },
    selectedLernpfadId() {
      return this.$store.state.learning.selectedLernpfadId;
    },
  },


  methods: {
    showAll() {
      this.$store.commit('setSelectedLernpfadId', null);
    },

    setSelectedLernpfadId(id) {
      this.iosVibrate(0);
      let reset = false;
      if (this.selectedLernpfadId !== id && this.mobile) reset = true;
      this.$store.commit('setSelectedLernpfadId', id);
      this.saveLastLernpfad(id);
      if (this.mobile) {
        if (reset) this.$store.commit('resetSelectedLernpfad');
        this.$router.push({ name: 'lernpfadMobile' });
      }
    },

    getFinishedLernspots(id) {
      const lernpfadIndex = this.lernpfadProgress?.lernpfade.findIndex((lernpfad) => lernpfad.id === id);
      if (lernpfadIndex === -1) {
        return 0;
      }
      let finishedSpots = 0;
      this.lernpfadProgress.lernpfade[lernpfadIndex].lernspots.forEach((lernspot) => {
        if (lernspot.finished) {
          finishedSpots += 1;
        }
      });
      return finishedSpots;
    },
  },
};
</script>
