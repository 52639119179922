<script>
export default {
  name: 'learningFunctions',

  computed: {
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    updateLastLernpfad() {
      return this.$store.state.updateLastLernpfad;
    },
  },

  methods: {
    saveLastLernpfad(lernpfadId) {
      const lastLernpfade = this?.me?.userData?.data?.attributes?.lastLernpfade;
      if (lastLernpfade) {
        let lernpfadeSavedInThisCourse = 0;
        let oldestIndex = null;
        lastLernpfade.forEach((lernpfad, index) => {
          if (lernpfad.courseId === this.currentCourseId) {
            lernpfadeSavedInThisCourse += 1;
            if (oldestIndex === null) oldestIndex = index;
          }
        });
        const lernpfadIndex = lastLernpfade.findIndex((lernpfad) => lernpfad.lernpfadId === lernpfadId);
        if (lernpfadIndex === -1 && lernpfadeSavedInThisCourse < 3) {
          lastLernpfade.push({
            courseId: this.currentCourseId,
            lernpfadId,
            updated: new Date(),
          });
        } else if (lernpfadIndex === -1 && lernpfadeSavedInThisCourse > 3) {
          lastLernpfade.splice(oldestIndex, 1);
          lastLernpfade.push({
            courseId: this.currentCourseId,
            lernpfadId,
            updated: new Date(),
          });
        } else {
          lastLernpfade.splice(lernpfadIndex, 1);
          lastLernpfade.push({
            courseId: this.currentCourseId,
            lernpfadId,
            updated: new Date(),
          });
        }
        this.updateLastLernpfad({ userDataId: this.userDataId, lastLernpfade })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              console.log(result);
            }
          });
      }
    },
  },

};
</script>
